export default Object.freeze({
  /**
   * 토큰 갱신
   */
  "/get/auth/refresh": {
    path: "/app/refund/cafe24/{1}/auth/refresh",
    method: "GET",
  },
  /**
   * 샵 리스트 조회
   */
  "/get/shops": {
    path: "/app/refund/mall/{1}/api/refund/shops",
    method: "GET",
  },
  /**
   * 이미지 업로드
   */
  "/upload/image": {
    path: "/app/refund/mall/{1}/api/refund/shops/{2}/upload/image",
    method: "POST",
  },
  /**
   * 카페24 상품 조회
   */
  "/get/cafe24/product": {
    path: "/app/refund/mall/{1}/api/refund/shops/{2}/products/search",
    method: "GET",
  },
  /**
   * 환불 설정 상품 리스트 조회
   */
  "/get/refund/product": {
    path: "/app/refund/mall/{1}/api/refund/shops/{2}/products",
    method: "GET",
  },
  /**
   * 환불 설정 등록 상품 리스트 조회(simple)
   */
  "/get/refund/product/registed": {
    path: "/app/refund/mall/{1}/api/refund/shops/{2}/registed/products",
    method: "GET",
  },
  /**
   * 환불 설정 상품 등록
   */
  "/post/refund/product": {
    path: "/app/refund/mall/{1}/api/refund/shops/{2}/products",
    method: "POST",
  },
  /**
   * 환불 설정 상품 수정
   */
  "/put/refund/product": {
    path: "/app/refund/mall/{1}/api/refund/shops/{2}/products/{3}",
    method: "PUT",
  },
  /**
   * 환불 설정 상품 삭제
   */
  "/delete/refund/product": {
    path: "/app/refund/mall/{1}/api/refund/shops/{2}/products/{3}",
    method: "DELETE",
  },
  /**
   * 환불 설정 상품 상세 조회
   */
  "/get/refund/product/detail": {
    path: "/app/refund/mall/{1}/api/refund/shops/{2}/products/{3}",
    method: "GET",
  },
  /**
   * 환불 주문 리스트 조회
   */
  "/get/refund/order": {
    path: "/app/refund/mall/{1}/api/refund/shops/{2}/orders",
    method: "GET",
  },
  /**
   * 환불 주문 상세 조회
   */
  "/get/refund/order/detail": {
    path: "/app/refund/mall/{1}/api/refund/shops/{2}/orders/{3}",
    method: "GET",
  },
  /**
   * 환불 주문 처리
   */
  "/put/refund/order": {
    path: "/app/refund/mall/{1}/api/refund/shops/{2}/orders/{3}",
    method: "PUT",
  },
  /**
   * Cafe24 환불 취소 처리 API 통신 요청
   */
  "/post/cafe24/order/cancel": {
    path: "/app/refund/mall/{1}/api/refund/shops/{2}/orders/{3}/cancel",
    method: "POST",
  },
  /**
   * 환불 주문 TID 조회
   */
  "/get/refund/order/tid": {
    path: "/app/refund/mall/{1}/api/refund/shops/{2}/orders/{3}/tid",
    method: "GET",
  },
  /**
   * 엑셀 다운로드 리스트
   */
  "/get/refund/excel": {
    path: "/app/refund/mall/{1}/api/refund/shops/{2}/excel",
    method: "GET",
  },
});
