import M from "@/libs/mapping.js";

const actions = {
  async getResultExcel({ commit }, params) {
    try {
      let result = await M.api(M.entry("/get/refund/excel", sessionStorage.getItem("refund_shop_no")), params);
      commit("setResultExcel", result);
    } catch (e) {
      //
    }
  },
};

export default actions;
