import actions from "./actions";
import mutations from "./mutations";

const state = {
  product_list_total_count: 0,
  product_search_count: 0,
  product_list: [],
  product_detail: [],
  cafe24_product_list: [],
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
