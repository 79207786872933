import Vue from "vue";
import VueRouter from "vue-router";
import RefundProductList from "@/views/RefundProductList.vue";
import RefundSetting from "@/views/RefundSetting.vue";
import RefundOrderList from "@/views/RefundOrderList.vue";
import ExcelManage from "@/views/ExcelManage.vue";

// 같은 페이지로 $router.push 한 경우 리로드 처리
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(() => {
    return window.location.reload();
  });
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: {
      name: "refund_product_list",
    },
  },
  {
    path: "/refund",
    redirect: {
      name: "refund_product_list",
    },
  },
  {
    path: "/refund/init",
    redirect: {
      name: "refund_product_list",
    },
  },
  {
    path: "/refund/product",
    name: "refund_product_list",
    component: RefundProductList,
  },
  {
    path: "/refund/product/setting",
    name: "refund_setting",
    component: RefundSetting,
  },
  {
    path: "/refund/order",
    name: "refund_order_list",
    component: RefundOrderList,
  },
  {
    path: "/refund/order/download",
    name: "refund_order_download",
    component: ExcelManage,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
