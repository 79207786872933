const mutations = {
  setResultRefund(state, params) {
    //state.result_order_refund_list_total_count = params.total_count;
    state.result_order_refund_list_search_count = params.search_count;
    state.result_order_refund_list = [];
    for (const param of params.list) {
      state.result_order_refund_list.push(param);
    }
  },
  setResultRefundDetail(state, params) {
    state.result_refund_detail = params;
  },
  setExcelResultRefund(state, params) {
    //state.excel_result_order_refund_list_total_count = params.total_count;
    state.excel_result_order_refund_list_search_count = params.search_count;
    state.excel_result_order_refund_list = [];
    for (const param of params.list) {
      state.excel_result_order_refund_list.push(param);
    }
  },
};

export default mutations;
