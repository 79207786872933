const mutations = {
  setResultExcel(state, params) {
    state.result_excel_list_search_count = params.search_count;
    state.result_excel_list = [];
    for (const param of params.list) {
      state.result_excel_list.push(param);
    }
  },
};

export default mutations;
