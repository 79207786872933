export default {
  name: "refund_header",
  data: () => ({}),
  methods: {
    mainMovePage: async function () {
      // 좌측 메뉴 리셋
      this.$parent.$refs.LeftMenu.reset();
      // shop 리스트 조회
      this.$parent.$refs.LeftMenu.$data.shop_no = sessionStorage.getItem("refund_shop_no");
      // 메인 페이지 이동
      this.$router.push({
        name: "main",
      });
    },
  },
  created: function () {},
};
