import { mapState } from "vuex";
import dayjs from "dayjs";
import Excel from "@/libs/excel.js";
import Filters from "@/libs/filters.js";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import Loading from "@/assets/images/loading.gif";
import HelpMemo from "@/components/common/HelpMemo.vue";
import RadialProgress from "@/components/common/RadialProgress.vue";

export default {
  name: "user_excel_download",
  data: () => ({
    Loading,
    page_no: 1,
    search_date_type_options: [
      { text: "주문일", value: "order_date" },
      { text: "결제일", value: "payment_date" },
    ],
    search_param: {
      search_date_type: "order_date",
      search_type: "product_code",
      search_text: "",
      from: null,
      to: null,
      order_status: ["N10", "N20,N30", "N21", "N22", "N40", "N50"],
      cs_status: ["C", "R", "E"],
      offset: 0,
      limit: 1000,
    },
    api_from: null,
    api_to: null,
    api_is_next_day: false,
    cs_code: {
      C: ["C00", "C10", "C11", "C34", "C35", "C36", "C40", "C41", "C42", "C43", "C47", "C48", "C49"],
      E: ["E00", "N01", "E11", "E12", "E13", "E20", "E30", "E31", "E32", "E33", "E34", "E35", "E36", "E40"],
      R: ["R00", "R10", "R11", "R13", "R20", "R12", "R30", "R31", "R34", "R36", "R40", "R41", "R42", "R43"],
    },
    next_limit: 1000,
    lang: {
      formatLocale: {
        months: ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"],
        monthsShort: ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"],
        weekdays: ["일요일", "월요알", "화요일", "수요일", "목요일", "금요일", "토요일"],
        weekdaysShort: ["일", "월", "화", "수", "목", "금", "토"],
        weekdaysMin: ["일", "월", "화", "수", "목", "금", "토"],
        firstDayOfWeek: 0,
        firstWeekContainsDate: 1,
      },
    },
    excel_data: {
      Excel: null,
      //total_limit: 60000,
      limit: 1000,
      total_count: 0,
      offset: 0,
      sheetData: [],
      sheetHeader: {
        payment_date: "결제일시(입금확인일)",
        order_date: "주문일시",
        order_id: "주문번호",
        order_item_code: "품목별 주문번호",
        product_code: "상품품목코드",
        product_name: "주문상품명",
        product_option: "상품옵션",
        product_add_option: "추가입력옵션",
        product_price: "상품구매금액",
        product_quantity: "수량",
        product_delivery_price: "상품당 배송비",
        delivery_info: "배송비 정보",
        product_total_amount: "상품별 주문금액",
        order_user_name: "주문자명",
        receiver_name: "수령인",
        order_user_phone: "주문자 휴대전화",
        receiver_phone: "수령인 휴대전화",
        receiver_zipcode: "수령인 우편번호",
        receiver_address: "수령인 주소(전체)",
        delivery_message: "배송메시지",
        payment_type: "결제구분",
        order_status: "주문상태",
        refund_status: "환불상태",
        partial_cancel: "부분취소/교환/반품",
        payment_method: "결제수단",
        use_point: "사용한 적립금액(최초)",
        payment_company: "결제업체",
        delivery_company: "배송업체",
        tracking_number: "운송장번호",
        delivery_start_date: "배송시작일",
        delivery_end_date: "배송완료일",
        order_place_number: "주문경로 주문번호(마켓/네이버페이 등)",
        order_place_item_number: "주문경로 품목별 주문번호(마켓/네이버페이 등)",
      },
    },
    search_date_btn_class: [
      {
        btnNormal: true,
        "mr-1": true,
        hover: true,
        selected: false,
      },
      {
        btnNormal: true,
        "mr-1": true,
        hover: true,
        selected: false,
      },
      {
        btnNormal: true,
        "mr-1": true,
        hover: true,
        selected: false,
      },
      {
        btnNormal: true,
        "mr-1": true,
        hover: true,
        selected: false,
      },
      {
        btnNormal: true,
        "mr-1": true,
        hover: true,
        selected: false,
      },
    ],
    search_loading: false,
    list_number: 0,
    is_excel_down: false,
    total_day_count: 0,
    ok_day_count: 0,
    progress: 0,
  }),
  computed: {
    ...mapState("excel", ["result_excel_list_search_count", "result_excel_list"]),
  },
  created: async function () {
    this.reset();

    this.searchDateChange(3, 1, "month");
    if (this.$route.query.from) {
      this.search_param.from = this.$route.query.from;
      this.search_param.to = this.$route.query.to;
    }
  },
  methods: {
    reset: function () {
      //Object.assign(this.$data, this.$options.data());
    },
    disabledStartDate: function (date) {
      return date > dayjs(this.search_param.to);
    },
    disabledEndDate: function (date) {
      return date < dayjs(this.search_param.from);
    },
    startDateCheck: function () {
      let result = true;
      try {
        let date = this.search_param.from.split("-");
        let y = parseInt(date[0], 10),
          m = parseInt(date[1], 10),
          d = parseInt(date[2], 10);

        let dateRegex =
          /^(?=\d)(?:(?:31(?!.(?:0?[2469]|11))|(?:30|29)(?!.0?2)|29(?=.0?2.(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00)))(?:\x20|$))|(?:2[0-8]|1\d|0?[1-9]))([-.\/])(?:1[012]|0?[1-9])\1(?:1[6-9]|[2-9]\d)?\d\d(?:(?=\x20\d)\x20|$))?(((0?[1-9]|1[012])(:[0-5]\d){0,2}(\x20[AP]M))|([01]\d|2[0-3])(:[0-5]\d){1,2})?$/;
        result = dateRegex.test(d + "-" + m + "-" + y);
      } catch (err) {
        result = false;
      }
      if (result === false) {
        alert("날짜를 확인해주세요.");
        this.search_param.from = dayjs().format("YYYY-MM-DD");
      }
    },
    endDateCheck: function () {
      let result = true;
      try {
        let date = this.search_param.to.split("-");
        let y = parseInt(date[0], 10),
          m = parseInt(date[1], 10),
          d = parseInt(date[2], 10);

        let dateRegex =
          /^(?=\d)(?:(?:31(?!.(?:0?[2469]|11))|(?:30|29)(?!.0?2)|29(?=.0?2.(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00)))(?:\x20|$))|(?:2[0-8]|1\d|0?[1-9]))([-.\/])(?:1[012]|0?[1-9])\1(?:1[6-9]|[2-9]\d)?\d\d(?:(?=\x20\d)\x20|$))?(((0?[1-9]|1[012])(:[0-5]\d){0,2}(\x20[AP]M))|([01]\d|2[0-3])(:[0-5]\d){1,2})?$/;
        result = dateRegex.test(d + "-" + m + "-" + y);
      } catch (err) {
        result = false;
      }
      if (result === false) {
        alert("날짜를 확인해주세요.");
        this.search_param.to = dayjs().format("YYYY-MM-DD");
      }
    },
    changeCsStatus: function (type, checked) {
      const checkboxes = document.getElementsByName("cs_status");
      if (type == "all") {
        this.search_param.cs_status = [];
        if (checked) {
          checkboxes.forEach((checkbox) => {
            checkbox.checked = checked;
            if (checkbox.value != "all") {
              this.search_param.cs_status.push(checkbox.value);
            }
          });
        }
      }
    },
    changeOrderStatus: function (type, checked) {
      const checkboxes = document.getElementsByName("order_status");
      if (type == "all") {
        this.search_param.order_status = [];
        if (checked) {
          checkboxes.forEach((checkbox) => {
            checkbox.checked = checked;
            if (checkbox.value != "all") {
              this.search_param.order_status.push(checkbox.value);
            }
          });
        }
      }
    },
    searchDateChange: function (index, number, type) {
      for (let search_date_btn of this.search_date_btn_class) {
        search_date_btn.selected = false;
      }
      this.search_date_btn_class[index].selected = true;

      const from = dayjs().subtract(number, type).format("YYYY-MM-DD");
      /*
      if (type == "month" && number == 3) {
        from = dayjs(from).add(1, "day").format("YYYY-MM-DD");
      }
      */
      const to = dayjs().format("YYYY-MM-DD");

      this.search_param.from = from;
      this.search_param.to = to;
    },
    excelDownload: async function () {
      if (this.is_excel_down == true) {
        alert("잠시만 기다려주세요. 다운로드 파일을 만드는 중입니다.");
        return;
      }
      if (this.search_param.order_status.length == 0 && this.search_param.cs_status.length == 0) {
        alert("예약상태 (주문상태) 또는 CS 상태를 1개 이상 선택해주세요.");
        return;
      }
      if (this.search_param.search_text.length > 0 && this.search_param.search_text.length < 8) {
        alert("검색어는 8자를 입력해주세요.");
        return;
      }

      //return; // view only
      // 엑셀
      /*
      this.excel_data.Excel = new Excel(null, null);
      await this.excel_data.Excel.initSheet();
      */

      this.progress = 0;
      this.ok_day_count = 0;
      this.is_excel_down = true;

      const param = this.makeParam(this.search_param);
      await this.$store.dispatch("excel/getResultExcel", param);
      this.excelDownloadCheck();
      /*
      this.createdExcel(excel_info);

      await this.excel_data.Excel.finishSheet(`${dayjs().format("YYYY-MM-DD")}-처방함량`);

      */
      //this.is_excel_down = false;
    },
    makeParam: function (check_param) {
      const param = JSON.parse(JSON.stringify(check_param));
      param.order_status = this.search_param.order_status.join(",");
      const cs_type = [];
      if (!this.api_from) {
        // 초기 설정 값
        console.log("init get parama", this.api_to, this.search_param.to);
        this.api_to = this.search_param.to;
        this.api_from = this.search_param.to; //dayjs(this.api_to).subtract(1, "day").format("YYYY-MM-DD");
      }
      if (this.api_is_next_day) {
        this.api_to = dayjs(this.api_to).subtract(1, "day").format("YYYY-MM-DD");
        this.api_from = dayjs(this.api_from).subtract(1, "day").format("YYYY-MM-DD");
        this.excel_data.offset = 0;
        this.api_is_next_day = false;
        console.log("re setting", this.api_to, this.api_from, this.excel_data);
      }
      param.offset = this.excel_data.offset;
      param.from = this.api_from;
      param.to = this.api_to;

      this.search_param.cs_status.forEach((cs) => {
        cs_type.push(this.cs_code[cs]);
      });
      if (cs_type.length) {
        param.cs_status = cs_type.join(",");
      } else {
        param.cs_status = null;
      }
      return param;
    },
    excelDownloadCheck: async function () {
      /*
      this.excel_data.total_count = this.result_excel_list_search_count;
      if (this.excel_data.total_count === 0) {
      if (this.result_excel_list.length === 0) {
        alert("엑셀 다운로드할 데이터가  없습니다. ");
        this.is_excel_down = false;
        return false;
      }
      */
      let excel_data_array = [];
      for (const item of this.result_excel_list) {
        const set_data = this.makeExcelData(item);
        excel_data_array.push(set_data);
      }
      this.excel_data.sheetData = excel_data_array;
      this.progress = 0;
      this.ok_day_count = 0;
      this.initExcelDownload();
    },
    initExcelDownload: function () {
      this.excel_data.Excel = new Excel(null, this.excel_data.sheetHeader);
      this.addExcel();
    },
    addExcel: async function () {
      if (this.excel_data.offset === 0 && this.api_to === this.search_param.to) {
        const fromDate = dayjs(this.search_param.from);
        const toDate = dayjs(this.search_param.to);
        this.total_day_count = toDate.diff(fromDate, "day", true);

        await this.excel_data.Excel.initSheet();
        await this.excel_data.Excel.addSheet(this.excel_data.sheetData);
        await this.getExcelDataAdd();
      } else {
        await this.getExcelDataAdd();
      }
    },
    getExcelDataAdd: async function () {
      /*
      if (this.excel_data.offset > this.excel_data.total_count) {
        await this.endExcel();
        return false;
      }
        */
      if (this.excel_data.offset === 0) {
        if (this.search_param.to === this.api_to) {
          this.excel_data.offset = this.excel_data.offset + this.excel_data.limit;
        }
      }

      let excel_payload = this.search_param;
      excel_payload["limit"] = this.next_limit;
      excel_payload["offset"] = this.excel_data.offset;
      excel_payload = this.makeParam(excel_payload);

      await this.$store.dispatch("excel/getResultExcel", excel_payload);
      let data = this.result_excel_list;
      //if (data.length === 0) {
      if (data.length < excel_payload["limit"]) {
        //console.log("end check", this.api_from, this.search_param.from, this.api_is_next_day);
        if (this.api_from == this.search_param.from) {
          await this.endExcel();
          return false;
        } else {
          this.api_is_next_day = true;
          this.ok_day_count++;
          this.progress = Math.floor((this.ok_day_count / this.total_day_count) * 100);
        }
      } else {
        this.excel_data.offset = this.excel_data.offset + this.excel_data.limit;
      }

      let excel_data_array = [];
      for (const item of this.result_excel_list) {
        let excel_datas = this.makeExcelData(item);
        excel_data_array.push(excel_datas);
      }
      this.excel_data.sheetData = excel_data_array;

      this.excel_data.Excel.addSheet(this.excel_data.sheetData);
      this.addExcel();
    },
    endExcel: async function () {
      await this.excel_data.Excel.endSheet(`주문 엑셀 다운로드_${dayjs().format("YYYYMMDD")}`);
      this.excel_data.offset = 0;
      this.search_param.offset = 0;
      this.api_from = null;
      this.api_to = null;
      this.api_is_next_day = false;
      this.is_excel_down = false;
    },
    makeExcelData: function (data) {
      return {
        payment_date: data.payment_date,
        order_date: data.order_date,
        order_id: data.order_id,
        order_item_code: data.order_item_code,
        product_code: data.product_code,
        product_name: data.product_name,
        product_option: data.product_option,
        product_add_option: data.product_add_option,
        product_price: data.product_price,
        product_quantity: data.product_quantity,
        product_delivery_price: data.product_delivery_price,
        delivery_info: data.delivery_info,
        product_total_amount: data.product_total_amount,
        order_user_name: data.order_user_name,
        receiver_name: data.receiver_name,
        order_user_phone: data.order_user_phone,
        receiver_phone: data.receiver_phone,
        receiver_zipcode: data.receiver_zipcode,
        receiver_address: data.receiver_address,
        delivery_message: data.delivery_message,
        payment_type: data.payment_type,
        order_status: data.order_status,
        refund_status: data.refund_status,
        partial_cancel: data.partial_cancel,
        payment_method: data.payment_method,
        use_point: data.use_point,
        payment_company: data.payment_company,
        delivery_company: data.delivery_company,
        tracking_number: data.tracking_number,
        delivery_start_date: data.delivery_start_date,
        delivery_end_date: data.delivery_end_date,
        order_place_number: data.order_place_number,
        order_place_item_number: data.order_place_item_number,
      };
    },
    createdExcel: async function (params, count, product_name) {
      for (const constent of params.constents) {
        const search_param_from = dayjs(this.search_param.from);
        const constent_created_at = dayjs(constent.created_at);
        if (search_param_from > constent_created_at) {
          continue;
        }

        let find = this.excel_data.sheetData.find((e) => e["bom_order_number"] == constent["bom_order_number"]);
        if (!find) {
          this.excel_data.sheetData.push(constent);
        }
      }

      this.excel_data.Excel.setHeaderKey(this.excel_data.sheetHeader);
      await this.excel_data.Excel.appendSheet(product_name, this.excel_data.sheetData);
    },
  },
  components: {
    DatePicker,
    HelpMemo,
    RadialProgress,
  },
  filters: Filters,
};
