import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import mutations from "./mutations";
import modules from "./modules";
import actions from "./actions";
import entries from "@/datas/entries.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    api_url: "https://order-refund-api.shoplus.store", // live
    // api_url: "http://localhost:15600/",
    app_code: "refund",
    entries,
  },
  getters: {},
  mutations,
  actions,
  modules: {
    common: modules.common,
    refund: modules.refund,
    order: modules.order,
    excel: modules.excel,
  },
  plugins: [
    createPersistedState({
      paths: ["common"],
    }),
  ],
});
