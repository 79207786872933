import { mapState } from "vuex";
import Filters from "@/libs/filters.js";
import { bank_code_list } from "@/datas/default_data";

export default {
  name: "refund_input_modal",
  data: () => ({
    is_show: false,
    order_id: null,
    order_status: "",
    real_order_id: null,
    order_user_id: null,
    order_user_name: null,
    order_user_phone: null,
    payment_method: "",
    payment_company: "",
    product_total_price: "0",
    actual_refund_amount: 0,
    expected_refund_amount: "0",
    penalty_price: "0",
    memo: null,
    refund_info: null,
    refund_date: null,
    cafe24_refund_date: null,
    refund_bank_code: "",
    refund_bank_account_no: "",
    refund_bank_account_owner: "",
    refund_bank_name: "",
    save_loading: false,
    is_cancel: true,
    is_penalty: false,
    cant_cancel: ["무통장", "가상계좌", "휴대폰", "선불금", "적립금"],
    can_cancel: ["신용카드", "계좌이체"],
    ok_partial: ["신용카드", "카카오페이"],
    no_partial: ["무통장", "휴대폰", "에스크로", "네이버페이", "실시간 계좌이체"],
    bank_code_list: bank_code_list,
    move_page_list: [
      { text: "신한은행", url: "https://bizbank.shinhan.com/main.html" },
      { text: "나이스 올더게이트", url: "https://npg.nicepay.co.kr/logIn.do" },
      { text: "휴대폰", url: "https://cp.mcash.co.kr/mcht/login.jsp" },
      { text: "카카오페이", url: "https://pg.kakao.com" },
      { text: "네이버페이", url: "https://admin.pay.naver.com" },
    ],
    move_select: "",
    is_api_ok: false,
  }),
  computed: {
    ...mapState("order", ["result_refund_detail"]),
  },
  methods: {
    reset: function () {
      this.memo = null;
      this.is_penalty = false;
      this.is_api_ok = false;
      this.cafe24_refund_date = null;
    },
    showModal: async function (set_data) {
      this.reset();
      this.is_show = true;
      this.order_id = set_data.order_id;
      this.order_status = set_data.order_status;
      try {
        const response = await this.$store.dispatch("order/getResultRefundDetail", { orderId: this.order_id });
        this.setDetailData();
      } catch (error) {
        console.error("Failed to fetch refund details:", error);
      }
      this.$parent.is_modal_show = true;
    },
    setDetailData: function () {
      const refundDetails = this.result_refund_detail;
      if (refundDetails.order_status) {
        this.order_status = refundDetails.order_status;
      }
      this.order_user_id = refundDetails.order_user_id;
      this.real_order_id = refundDetails.order_id;
      this.order_user_name = refundDetails.order_user_name;
      this.order_user_phone = refundDetails.order_user_phone;
      this.payment_method = refundDetails.payment_method;
      this.payment_company = refundDetails.payment_company;
      this.product_total_price = refundDetails.product_order_amount;
      this.real_refund_amount = refundDetails.product_actual_refund_amount;

      this.expected_refund_amount = refundDetails.product_expect_refund_amount;
      this.penalty_price = refundDetails.product_penalty_price;
      this.memo = refundDetails.refund_memo;
      this.refund_info = refundDetails.refund_info;
      this.actual_refund_amount = refundDetails.product_actual_refund_amount;
      this.is_cancel = this.can_cancel.includes(this.payment_method);
      this.is_penalty = this.penalty_price > 0;
      this.refund_date = refundDetails.refund_date;
      if (refundDetails.cafe24_refund_date) {
        this.cafe24_refund_date = refundDetails.cafe24_refund_date;
        this.is_api_ok = true;
      }
      if (refundDetails.refund_bank_code) {
        this.refund_bank_code = refundDetails.refund_bank_code;
        this.refund_bank_account_no = refundDetails.refund_bank_account_no;
        this.refund_bank_account_owner = refundDetails.refund_bank_account_owner;
        this.refund_bank_name = refundDetails.refund_bank_name;
      } else if (refundDetails.refund_info[0].value) {
        this.refund_bank_name = refundDetails.refund_info[0].value;
        this.refund_bank_account_no = refundDetails.refund_info[1].value;
        this.refund_bank_account_owner = refundDetails.refund_info[2].value;
        this.refund_bank_code = this.bank_code_list.find((item) => item.value === this.refund_bank_name).code;
      } else {
        this.refund_bank_code = "";
        this.refund_bank_account_no = "";
        this.refund_bank_account_owner = "";
        this.refund_bank_name = "";
      }
      console.log(this.order_status, this.cafe24_refund_date, "refund value check");
    },
    closeModal: function () {
      this.is_show = false;
      this.$parent.is_modal_show = false;
    },
    save: async function (type) {
      if (this.save_loading == true) {
        return;
      }

      if (type === "api" && !this.cafe24_refund_date) {
        let confirm_msg = "";
        if (this.actual_refund_amount == this.product_total_price) {
          confirm_msg = `실제 환불금액이 전액 입력되어 취소완료(환불완료)로 연동됩니다.`;
        } else {
          confirm_msg = `실제 환불금액이 부분 입력되어 환불처리 연동을 진행하지 않으므로 취소처리중(환불전) 상태로 연동됩니다.`;
        }
        if (!confirm(confirm_msg + `\n실제 고객 환불을 진행하시고 연동 처리하는 것을 권장합니다.\n처리하시겠습니까?`)) {
          return false;
        }
      }
      this.save_loading = true;

      const set_param = {
        order_id: this.order_id,
        actual_refund_amount: this.is_penalty ? this.actual_refund_amount : this.expected_refund_amount,
        refund_memo: this.memo,
        refund_bank_account_no: this.refund_bank_account_no,
        refund_bank_account_owner: this.refund_bank_account_owner,
        refund_bank_code: this.refund_bank_code,
        refund_bank_name: this.refund_bank_code ? bank_code_list.find((item) => item.code === this.refund_bank_code).value : "",
      };
      let result = null;
      if (type === "api") {
        console.log("api call start");
        result = await this.$store.dispatch("order/postCafe24Cancel", set_param);
      } else {
        console.log("save call start");
        result = await this.$store.dispatch("order/putPenalty", set_param);
      }
      this.save_loading = false;
      alert("저장되었습니다.");

      if (result) {
        this.$parent.updateRefundInfo(result);
        if (type === "api" && result.cafe24_refund_date) {
          this.is_api_ok = true;
          this.cafe24_refund_date = result.cafe24_refund_date;
          this.order_status = result.order_status;
        }
      }
      //this.closeModal();
    },
    moveMemberAdminPage: function (member_id) {
      const refund_mall_id = sessionStorage.getItem("refund_mall_id");
      window.open(`https://${refund_mall_id}.cafe24.com/admin/php/shop1/c/member_admin_d_f.php?user_id=` + member_id, "_blank");
    },
    moveRefundWindow: function () {
      window.open(this.move_select, "_blank");
      /*
      if (this.ok_partial.includes(this.payment_method)) {
        window.open("https://npg.nicepay.co.kr/logIn.do");
      } else {
        window.open("https://bizbank.shinhan.com/main.html");
      }
        */
    },
    isPartialCheck: function (type) {
      if (type == "pg") {
        return this.is_penalty && this.ok_partial.includes(this.payment_method);
      } else if (type == "bank") {
        return this.is_penalty && !this.ok_partial.includes(this.payment_method);
      }
    },
    onlyNumber: function (e) {
      let val = e.target.value;
      let replaceVal = val.replace(/[^0-9]/g, "");
      e.target.value = Number(replaceVal);
      var event = new Event("input", {
        bubbles: true,
        cancelable: true,
      });
      e.target.dispatchEvent(event);
    },
    moveOrderDetail: function (order_id) {
      if (order_id) {
        const real_order_id = order_id.substring(0, 16);
        console.log(real_order_id);
        Filters.linkOrderDetail(real_order_id);
      }
    },
    displayOrderStatus: function (order_status) {
      if (order_status != null) {
        const reservationStatus = Filters.reservationStatusType(order_status);
        const orderStatus = Filters.orderStatusType(order_status);
        return order_status.startsWith("N") ? `${reservationStatus} (${orderStatus})` : orderStatus;
      }
      return "";
    },
  },
  filters: Filters,
};
