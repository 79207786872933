<template>
  <div class="radial-progress">
    <svg viewBox="0 0 36 36">
      <path class="circle-bg" d="M18 2.0845
           a 15.9155 15.9155 0 0 1 0 31.831
           a 15.9155 15.9155 0 0 1 0 -31.831" />
      <path class="circle" :stroke-dasharray="`${progress}, 100`" d="M18 2.0845
           a 15.9155 15.9155 0 0 1 0 31.831
           a 15.9155 15.9155 0 0 1 0 -31.831" />
      <text x="18" y="20.35" class="percentage">{{ progress }}%</text>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'RadialProgress',
  props: {
    progress: {
      type: Number,
      required: true,
      validator: value => value >= 0 && value <= 100
    }
  },
  created() {
    this.progress = 0;
  },
  methods: {
    setProgress(progress) {
      this.progress = progress;
    }
  }
}
</script>

<style scoped>
.radial-progress {
  width: 100px;
  height: 100px;
}

.circle-bg {
  fill: none;
  stroke: #eee;
  stroke-width: 3.8;
}

.circle {
  fill: none;
  stroke: #3971ff;
  stroke-width: 2.8;
  stroke-linecap: round;
  transition: stroke-dasharray 0.3s ease;
}

.percentage {
  fill: white;
  font-family: Arial, sans-serif;
  font-size: 0.5em;
  text-anchor: middle;
}
</style>