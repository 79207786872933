import { mapState } from "vuex";

export default {
  name: "left_menu",
  data: () => ({
    class_config: {},
    sub_menu_class: {
      refund_product_list: {
        sub_menu_on: false,
      },
      refund_order_list: {
        sub_menu_on: false,
      },
      refund_order_download: {
        sub_menu_on: false,
      },
    },
    shop_no: 1,
  }),
  computed: {
    ...mapState("common", ["shop_list"]),
  },
  methods: {
    reset: function () {
      Object.assign(this.$data, this.$options.data());
    },
    menuToggle: function (name) {
      if (!this.class_config[name]) {
        return;
      }
      if (this.class_config[name].collapsable === true) {
        this.class_config[name].collapsable = false;
      } else {
        this.class_config[name].collapsable = true;
      }
    },
    movePage: function (page_name) {
      this.setSubMenuColor(page_name);
      this.$router.push({
        name: `${page_name}`,
      });
    },
    /*
    setMainMenuColor: function (name) {
      if (!this.class_config[name]) {
        return;
      }
      this.class_config[name].collapsable = true;
    },
    */
    setSubMenuColor: function (name) {
      if (!this.sub_menu_class[name]) {
        return;
      }
      Object.assign(this.$data.sub_menu_class, this.$options.data().sub_menu_class);
      this.sub_menu_class[name].sub_menu_on = true;
    },
    getShops: async function () {
      await this.$store.dispatch("common/getShops");
    },
  },
  created: async function () {
    console.log(this.$route.name);
    let route_name_array = this.$route.name.split("_");
    let name = route_name_array[0];
    //this.setMainMenuColor(name);
    this.setSubMenuColor(this.$route.name.replace("_save", "_list"));
  },
  mounted: async function () {},
};
