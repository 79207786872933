import M from "@/libs/mapping.js";

const actions = {
  async getResultRefund({ commit }, params) {
    try {
      let result = await M.api(M.entry("/get/refund/order", sessionStorage.getItem("refund_shop_no")), params);
      commit("setResultRefund", result);
    } catch (e) {
      //
    }
  },
  async getResultRefundReturn(commit, params) {
    try {
      return await M.api(M.entry("/get/refund/order", sessionStorage.getItem("refund_shop_no")), params);
    } catch (e) {
      //
    }
  },
  // Modal 창 열때 상세 조회
  async getResultRefundDetail({ commit }, params) {
    try {
      const result = await M.api(M.entry("/get/refund/order/detail", sessionStorage.getItem("refund_shop_no"), params.orderId));
      commit("setResultRefundDetail", result);
    } catch (e) {
      //
    }
  },
  // Modal 값 저장
  async putPenalty({ commit }, params) {
    try {
      const item_code = params.order_id;
      const result = await M.api(M.entry("/put/refund/order", sessionStorage.getItem("refund_shop_no"), item_code), params);
      return result;
    } catch (e) {
      // console.log(e);
    }
  },
  // Cafe24 취소 API통신
  async postCafe24Cancel({ commit }, params) {
    try {
      const item_code = params.order_id;
      console.log("cafe 24 cancel", item_code);
      const result = await M.api(M.entry("/post/cafe24/order/cancel", sessionStorage.getItem("refund_shop_no"), item_code), params);
      return result;
    } catch (e) {
      // console.log(e);
    }
  },
  async getResultRefundDetailReturn(commit, params) {
    try {
      return await M.api(M.entry("/get/refund/order/detail", sessionStorage.getItem("refund_shop_no"), params.result_id));
    } catch (e) {
      //
    }
  },
  async getOrderTid(commit, params) {
    try {
      return await M.api(M.entry("/get/refund/order/tid", sessionStorage.getItem("refund_shop_no"), params));
    } catch (e) {
      console.log(e);
    }
  },
  // 엑셀다운로드시 사용
  async getResultRefundDetailsReturn(commit, params) {
    try {
      return await M.api(M.entry("/get/refund/order/details", sessionStorage.getItem("refund_shop_no")), params);
    } catch (e) {
      //
    }
  },
  async getExcelResultRefund({ commit }, params) {
    try {
      const result = await M.api(M.entry("/get/refund/order", sessionStorage.getItem("refund_shop_no")), params);
      commit("setExcelResultRefund", result);
    } catch (e) {
      //
    }
  },
};

export default actions;
