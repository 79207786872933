import actions from "./actions";
import mutations from "./mutations";

const state = {
  result_order_refund_list_total_count: 0,
  result_order_refund_list_search_count: 0,
  result_order_refund_list: [],
  result_refund_detail: null,
  excel_result_order_refund_list_total_count: null,
  excel_result_order_refund_list_search_count: 0,
  excel_result_order_refund_list: [],
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
