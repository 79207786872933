const mutations = {
  setRefundProduct(state, products) {
    state.product_list = [];
    state.product_list_total_count = products.total_count;
    state.product_search_count = products.search_count;
    for (const product of products.list) {
      state.product_list.push(product);
    }
  },
  setRefundProductDetail(state, product) {
    console.log("setRefundProductDetail", product, state);
    state.product_detail = product;
  },
  setProductList(state, products) {
    state.product_list_total_count = products.total_count;
    state.product_search_count = products.search_count;
    state.product_list = products.list;
  },
  setCafe24ProductList(state, products) {
    console.log("setCafe24ProductList", products, state);
    state.cafe24_product_list = products;
  },
  setRefundProductSimple(state, products) {
    state.product_list = products;
  },
};

export default mutations;
