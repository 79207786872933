import { mapState } from "vuex";
import Paginate from "@/components/common/Paginate.vue";
import dayjs from "dayjs";
import Excel from "@/libs/excel.js";
import Filters from "@/libs/filters.js";
import DatePicker from "vue2-datepicker";
import RefundInputModal from "@/views/modal/RefundInputModal.vue";
import "vue2-datepicker/index.css";
import Loading from "@/assets/images/loading.gif";
import HelpMemo from "@/components/common/HelpMemo.vue";
import { select_day_type, bank_code_list } from "@/datas/default_data";
//const XLSX = require("xlsx");

export default {
  name: "order_refund_list",
  data: () => ({
    refund_mall_id: null,
    Loading,
    excel_loading: false,
    is_getting: true,
    page_no: 1,
    is_modal_show: false,
    search_date_type_options: select_day_type,
    search_param: {
      from: null,
      to: null,
      search_date_type: "order_date",
      refund_product_code: "all",
      search_type: "order_id",
      search_text: null,
      order_user_id: null,
      order_user_name: null,
      order_user_phone: null,
      cs_status: ["C0", "C1"],
      order_status: [],
      offset: 0,
      limit: 20,
    },
    lang: {
      formatLocale: {
        months: ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"],
        monthsShort: ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"],
        weekdays: ["일요일", "월요일", "화요일", "수요일", "목요일", "금요일", "토요일"],
        weekdaysShort: ["일", "월", "화", "수", "목", "금", "토"],
        weekdaysMin: ["일", "월", "화", "수", "목", "금", "토"],
        firstDayOfWeek: 0,
        firstWeekContainsDate: 1,
      },
    },
    search_type_options: [
      { text: "주문번호(예약번호)", value: "order_id" },
      { text: "상품명", value: "product_name" },
      { text: "상품코드", value: "product_code" },
      { text: "공급사명", value: "supplier_name" },
      { text: "공급사코드", value: "supplier_code" },
    ],
    excel_data: {
      Excel: null,
      total_limit: 60000,
      limit: 300,
      total_count: 0,
      offset: 0,
      sheetData: [],
      sheetHeader: {
        order_date: "주문일",
        payment_date: "결제일",
        cancel_request_date: "취소신청일",
        cancel_accept_date: "취소접수일",
        order_id: "주문번호",
        order_item_code: "품목주문번호",
        order_status: "예약상태(주문상태)",
        product_name: "상품명",
        order_option: "주문옵션",
        order_user_name: "주문자명",
        order_user_id: "주문자아이디",
        order_user_phone: "주문자휴대폰",
        used_date: "이용일",
        product_price: "판매가",
        product_quantity: "수량",
        product_total_price: "상품구매금액",
        penalty_price: "위약금",
        expected_refund_amount: "예상환불금액",
        actual_refund_amount: "실제환불금액",
        remain_amount: "최종잔액",
        refund_memo: "환불메모 (어드민 입력)",
        payment_method: "결제수단",
        //payment_amount: "결제금액",
        use_point: "적립금사용",
        payment_company: "결제업체",
      },
    },
    refund_product_list: [],
    order_refund_list: [],
    //order_refund_list_total_count: 0,
    order_refund_list_search_count: 0,
    excel_order_refund_list: [],
    search_date_btn_class: [
      {
        btnNormal: true,
        "mr-1": true,
        hover: true,
        selected: false,
      },
      {
        btnNormal: true,
        "mr-1": true,
        hover: true,
        selected: false,
      },
      {
        btnNormal: true,
        "mr-1": true,
        hover: true,
        selected: false,
      },
      {
        btnNormal: true,
        "mr-1": true,
        hover: true,
        selected: false,
      },
      {
        btnNormal: true,
        "mr-1": true,
        hover: true,
        selected: false,
      },
    ],
    search_loading: false,
  }),
  computed: {
    ...mapState("order", [
      //"result_order_refund_list_total_count",
      "result_order_refund_list_search_count",
      "result_order_refund_list",
      "refund_detail",
      /*
      "excel_result_refund_list_total_count",
      "excel_result_refund_list_search_count",
      */
      "excel_result_order_refund_list",
    ]),
    ...mapState("refund", ["product_list"]),
  },
  created: async function () {
    this.reset();
    this.refund_mall_id = sessionStorage.getItem("refund_mall_id");
    await this.getProductList();
    if (this.$route.query && this.$route.query.page_no) {
      this.page_no = Number(this.$route.query.page_no);
    } else {
      this.page_no = 1;
    }
    document.querySelector(".components").addEventListener("scroll", this.handleScroll);

    //this.search_param.from = dayjs().format("YYYY-MM-DD");
    //this.search_param.to = dayjs().format("YYYY-MM-DD");
    this.searchDateChange(3, 1, "month");

    if (this.$route.query.from) {
      if (this.$route.query.from && this.$route.query.to) {
        this.search_param.to = this.$route.query.to;
        const today = dayjs().format("YYYY-MM-DD");
        if (this.$route.query.to === today) {
          const fromDate = dayjs(this.$route.query.from);
          const toDate = dayjs(this.$route.query.to);
          const diffInDays = toDate.diff(fromDate, "day");
          if (diffInDays == 0) {
            this.searchDateChange(0, 0, "day");
          } else if (diffInDays == 3) {
            this.searchDateChange(1, 3, "day");
          } else if (diffInDays == 7) {
            this.searchDateChange(2, 7, "day");
          } else {
            const diffInMonths = toDate.diff(fromDate, "month");
            if (diffInMonths == 1) {
              this.searchDateChange(3, 1, "month");
            } else if (diffInMonths == 3) {
              this.searchDateChange(4, 3, "month");
            } else {
              this.search_param.from = this.$route.query.from;
            }
          }
        } else {
          this.search_param.from = this.$route.query.from;
        }
      }
      if (this.$route.query.refund_product_code) {
        this.search_param.refund_product_code = this.$route.query.refund_product_code;
      }
      if (this.$route.query.search_date_type) {
        this.search_param.search_type = this.$route.query.search_date_type;
      }
      if (this.$route.query.search_type) {
        this.search_param.search_type = this.$route.query.search_type;
      }
      if (this.$route.query.search_text) {
        this.search_param.search_text = this.$route.query.search_text;
      }
      if (this.$route.query.order_user_id) {
        this.search_param.order_user_id = this.$route.query.order_user_id;
      }
      if (this.$route.query.order_user_name) {
        this.search_param.order_user_name = this.$route.query.order_user_name;
      }
      if (this.$route.query.order_user_phone) {
        this.search_param.order_user_phone = this.$route.query.order_user_phone;
      }
      this.search_param.cs_status = [];
      if (this.$route.query.cs_type) {
        const checkboxes = document.getElementsByName("cs_status");
        checkboxes.forEach((checkbox) => {
          if (this.$route.query.cs_type.indexOf(checkbox.value) !== -1) {
            this.search_param.cs_status.push(checkbox.value);
            //checkbox.checked = true;
          } else {
            //checkbox.checked = false;
          }
        });
      }
      this.search_param.order_status = [];
      if (this.$route.query.order_type) {
        const checkboxes = document.getElementsByName("order_status");
        checkboxes.forEach((checkbox) => {
          if (this.$route.query.order_type.indexOf(checkbox.value) !== -1) {
            this.search_param.order_status.push(checkbox.value);
            checkbox.checked = true;
          } else {
            checkbox.checked = false;
          }
        });
      }
    }

    await this.searchResultRefund();
    this.setPaging();
  },
  beforeDestroy: function () {
    document.querySelector(".components").removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    fetchOrders: async function () {
      this.search_loading = true;
      await this.getResultRefund();
      await this.setResultRefund();
      this.setPaging();
      this.search_loading = false;
    },
    reset: function () {
      Object.assign(this.$data, this.$options.data());
    },
    setPaging: function () {
      return;
      this.$refs.Paginate.setPaging(
        this.order_refund_list_search_count,
        this.search_param.limit,
        this.order_refund_list.length,
        this.order_refund_list_search_count,
      );
    },
    changeCsType: function (type, checked) {
      const checkboxes = document.getElementsByName("cs_status");
      if (type == "all") {
        this.search_param.cs_status = [];
        if (checked) {
          checkboxes.forEach((checkbox) => {
            checkbox.checked = checked;
            if (checked) {
              if (checkbox.value != "all") {
                this.search_param.cs_status.push(checkbox.value);
              }
            }
          });
        }
      }
    },
    changeOrderStatus: function (type, checked) {
      const checkboxes = document.getElementsByName("order_status");
      if (type == "all") {
        this.search_param.order_status = [];
        if (checked) {
          checkboxes.forEach((checkbox) => {
            checkbox.checked = checked;
            if (checked) {
              if (checkbox.value != "all") {
                this.search_param.order_status.push(checkbox.value);
              }
            }
          });
        }
        const allIndex = this.search_param.order_status.indexOf("all");
        if (allIndex > -1) {
          this.search_param.order_status.splice(allIndex, 1);
        }
      }
    },
    linkRefundDetail: function (product_code) {
      return `/refund/product/?product_code=${product_code}`;
    },
    /*
    linkOrderDetail: function (order_id) {
      return `https://${this.refund_mall_id}.cafe24.com/admin/php/shop1/s_new/order_detail.php?order_id=${order_id}`;
    },
    */
    disabledStartDate: function (date) {
      return date > dayjs(this.search_param.to);
    },
    disabledEndDate: function (date) {
      return date < dayjs(this.search_param.from);
    },
    startDateCheck: function () {
      let result = true;
      try {
        let date = this.search_param.from.split("-");
        let y = parseInt(date[0], 10),
          m = parseInt(date[1], 10),
          d = parseInt(date[2], 10);

        let dateRegex =
          /^(?=\d)(?:(?:31(?!.(?:0?[2469]|11))|(?:30|29)(?!.0?2)|29(?=.0?2.(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00)))(?:\x20|$))|(?:2[0-8]|1\d|0?[1-9]))([-.\/])(?:1[012]|0?[1-9])\1(?:1[6-9]|[2-9]\d)?\d\d(?:(?=\x20\d)\x20|$))?(((0?[1-9]|1[012])(:[0-5]\d){0,2}(\x20[AP]M))|([01]\d|2[0-3])(:[0-5]\d){1,2})?$/;
        result = dateRegex.test(d + "-" + m + "-" + y);
      } catch (err) {
        result = false;
      }
      if (result === false) {
        alert("날짜를 확인해주세요.");
        this.search_param.from = dayjs().format("YYYY-MM-DD");
      }
    },
    endDateCheck: function () {
      let result = true;
      try {
        let date = this.search_param.to.split("-");
        let y = parseInt(date[0], 10),
          m = parseInt(date[1], 10),
          d = parseInt(date[2], 10);

        let dateRegex =
          /^(?=\d)(?:(?:31(?!.(?:0?[2469]|11))|(?:30|29)(?!.0?2)|29(?=.0?2.(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00)))(?:\x20|$))|(?:2[0-8]|1\d|0?[1-9]))([-.\/])(?:1[012]|0?[1-9])\1(?:1[6-9]|[2-9]\d)?\d\d(?:(?=\x20\d)\x20|$))?(((0?[1-9]|1[012])(:[0-5]\d){0,2}(\x20[AP]M))|([01]\d|2[0-3])(:[0-5]\d){1,2})?$/;
        result = dateRegex.test(d + "-" + m + "-" + y);
      } catch (err) {
        result = false;
      }
      if (result === false) {
        alert("날짜를 확인해주세요.");
        this.search_param.to = dayjs().format("YYYY-MM-DD");
      }
    },
    getProductList: async function () {
      await this.$store.dispatch("refund/getRefundProductListSimple");
      this.setProductList();
    },
    setProductList: async function () {
      this.refund_product_list = this.product_list;
    },
    getResultRefund: async function () {
      this.$set(this.search_param, "offset", (this.page_no - 1) * this.search_param.limit);
      let set_param = JSON.parse(JSON.stringify(this.search_param));
      if (!set_param.search_text) {
        set_param.search_type = null;
      }
      if (!this.search_param.cs_status) {
        set_param.cs_type = null;
      } else {
        if (this.search_param.cs_status.length === 4) {
          set_param.cs_type = "all";
        } else {
          set_param.cs_type = this.search_param.cs_status.join(",");
        }
      }
      if (!this.search_param.order_status) {
        set_param.order_type = null;
      } else {
        if (this.search_param.order_status.length === 6) {
          set_param.order_type = "all";
        } else {
          set_param.order_type = this.search_param.order_status.join(",");
        }
      }
      delete set_param.cs_status;
      delete set_param.order_status;

      //console.log("getResultRefund check", this.search_param, set_param, this.search_param.cs_status);
      await this.$store.dispatch("order/getResultRefund", set_param);
    },
    setResultRefund: async function () {
      //this.order_refund_list_total_count = Number(this.result_order_refund_list_total_count);
      const real_get_count = Number(this.result_order_refund_list_search_count);
      this.order_refund_list_search_count = real_get_count + this.order_refund_list_search_count;
      this.is_getting = 0 < real_get_count;

      for (const order_refund of this.result_order_refund_list) {
        this.order_refund_list.push(order_refund);
      }
    },
    searchResultRefund: async function (type) {
      console.log("search check", this.search_param);
      if (type == "search") {
        let query = {};
        // 3개월 날짜 체크
        const fromDate = dayjs(this.search_param.from);
        const toDate = dayjs(this.search_param.to);
        const diffInMonths = toDate.diff(fromDate, "month", true);

        if (diffInMonths > 3) {
          alert("검색 기간은 최대 3개월 이내로 설정해주세요.");
          return;
        }
        if (this.search_param.cs_status.length == 0 && this.search_param.order_status.length == 0) {
          alert("예약상태 (주문상태) 또는 CS 상태를 1개 이상 선택해주세요.");
          return;
        }

        if (this.search_param.refund_product_code) {
          query = Object.assign({}, query, { refund_product_code: this.search_param.refund_product_code });
        }

        if (this.search_param.search_date_type) {
          query = Object.assign({}, query, { search_date_type: this.search_param.search_date_type });
        }
        if (this.search_param.from) {
          query = Object.assign({}, query, { from: this.search_param.from });
        }
        if (this.search_param.to) {
          query = Object.assign({}, query, { to: this.search_param.to });
        }
        if (this.search_param.search_type) {
          const type = this.search_param.search_type;
          const value = this.search_param.search_text;
          if (type == "product_code" && value.length !== 8) {
            alert("상품코드는 8자로 입력해주세요.");
            return false;
          } else if (["product_name", "supplier_name"].includes(type) && value.length > 0 && value.length < 3) {
            alert("3글자 이상으로 입력해주세요.");
            return false;
          } else if (type == "supplier_code" && value.length === 8) {
            alert("공급사코드는 8자로 입력해주세요.");
            return false;
          }
          query = Object.assign({}, query, { search_type: this.search_param.search_type });
        }
        if (this.search_param.search_text) {
          query = Object.assign({}, query, { search_text: this.search_param.search_text });
        }
        if (this.search_param.order_user_name) {
          query = Object.assign({}, query, { order_user_name: this.search_param.order_user_name });
        }
        if (this.search_param.order_user_id) {
          query = Object.assign({}, query, { order_user_id: this.search_param.order_user_id });
        }
        if (this.search_param.order_user_phone) {
          query = Object.assign({}, query, { order_user_phone: this.search_param.order_user_phone });
        }
        if (this.search_param.cs_status.length > 0) {
          query = Object.assign({}, query, { cs_type: this.search_param.cs_status.join(",") });
        }
        if (this.search_param.order_status.length > 0) {
          query = Object.assign({}, query, { order_type: this.search_param.order_status.join(",") });
        }
        /*
        this.$router.push({
          name: "refund_order_list",
          query,
        });
        return;
        */
      }
      if (this.search_loading == true) {
        return;
      }
      this.order_refund_list = [];
      //this.search_param.offset = 0;
      this.order_refund_list_search_count = 0;
      this.page_no = 1;
      this.fetchOrders();
      /*
      this.search_loading = true;
      await this.getResultRefund();
      await this.setResultRefund();
      this.setPaging();
      this.search_loading = false;
      */
    },
    handleScroll: function (event) {
      const { scrollTop, scrollHeight, clientHeight } = event.target;
      const bottomOfWindow = scrollTop + clientHeight >= scrollHeight;
      if (bottomOfWindow && this.is_getting) {
        this.page_no++;
        this.fetchOrders();
      }
    },
    /**
     * 엑셀 다운로드 체크
     */
    /*
    excelDownloadSheet: async function () {
      // button Click
      if (this.excel_loading == true) {
        return;
      }

      this.excel_loading = true;
      this.excel_data.total_count = 0;
      this.excel_data.offset = 0;
      let excel_payload = this.search_param;

      // 1개만 조회해서 검색 개수 체크
      excel_payload["limit"] = 1;
      excel_payload["offset"] = 0;
      //await this.$store.dispatch("order/getExcelResultRefund", excel_payload);
      // total_limit 많으면 중단
      if (this.excel_result_refund_list_search_count > this.excel_data.total_limit) {
        alert("엑셀 다운로드 건 수는 " + this.excel_data.total_limit.toLocaleString("ko-KR") + "건</br>이내로 검색하여 다운로드 해주세요.");
        this.excel_loading = false;
        return false;
      }
      if (this.order_refund_list_search_count > 100000) {
        alert("10만건 이상은 다운로드 시간이 다소 걸릴수 있습니다.");
      }
      // limit대로 조회 시작
      excel_payload["limit"] = this.excel_data.limit;
      //let count = Math.ceil(this.excel_result_refund_list_search_count / excel_payload["limit"]);
      let count = Math.ceil(this.order_refund_list_search_count / excel_payload["limit"]);

      let headers = {};
      let bodys = {};

      for (let i = 0; i < count; i++) {
        excel_payload["limit"] = this.excel_data.limit;
        excel_payload["offset"] = i * this.excel_data.limit;

        //let result_refund_list = await this.$store.dispatch("order/getResultRefundDetailsReturn", excel_payload);

        for (let result of order_refund_list) {
          // 헤더 만들기
          // 바디 만들기
        }
        // 내용 만들기
        for (let result of order_refund_list) {
          // 바디 세팅
          let add_body = {
            manage_product_name: result.manage_product_name,
            manage_product_nick_name: result.manage_product_nick_name,
            osdl: result.osdl ? result.osdl : "-",
            member_id: result.member_id ? result.member_id : "-",
          };
        }
      }

      const workbook = XLSX.utils.book_new();

      for (const header in headers) {
        let result = [];
        bodys[header].forEach((list) => {
          let data = {};
          for (let k in list) {
            for (let h in headers[header]) {
              if (h === k) {
                data[headers[header][h]] = list[k];
              }
            }
          }
          if (data !== null) {
            result.push(data);
          }
        });
        let header_value = Object.values(headers[header]);

        let _worksheet = XLSX.utils.json_to_sheet(result, {
          header: header_value,
        });

        XLSX.utils.book_append_sheet(workbook, _worksheet, header);
      }

      XLSX.writeFile(workbook, `소비자_문진결과_관리_${dayjs().format("YYYY-MM-DD")}.xlsx`);

      this.excel_loading = false;
    },
     */
    /**
     * 엑셀 다운로드 체크
     */
    excelDownload: async function () {
      if (this.excel_loading == true) {
        alert("잠시만 기다려주세요.");
        return;
      }
      this.excel_loading = true;
      this.excel_data.total_count = 0;
      this.excel_data.offset = 0;
      let excel_payload = JSON.parse(JSON.stringify(this.search_param));
      //let excel_payload = this.search_param;
      excel_payload["limit"] = this.excel_data.limit;
      excel_payload["offset"] = 0;
      if (this.search_param.cs_status.length === 0) {
        excel_payload["cs_type"] = null;
      } else {
        if (this.search_param.cs_status.length === 4) {
          excel_payload["cs_type"] = "all";
        } else {
          excel_payload["cs_type"] = this.search_param.cs_status.join(",");
        }
      }
      if (this.search_param.order_status.length === 0) {
        excel_payload["order_type"] = null;
      } else {
        if (this.search_param.order_status.length === 6) {
          excel_payload["order_type"] = "all";
        } else {
          excel_payload["order_type"] = this.search_param.order_status.join(",");
        }
      }
      delete excel_payload["order_status"];
      delete excel_payload["cs_status"];

      //console.log("get Excel Data start", this.search_param, excel_payload);
      await this.$store.dispatch("order/getExcelResultRefund", excel_payload);
      await this.excelDownloadCheck();
    },
    excelDownloadCheck: async function () {
      let data = this.excel_result_order_refund_list;
      //let data = this.order_refund_list;

      if (data.length === 0) {
        alert("엑셀 다운로드할 데이터가 없습니다.");
        this.excel_loading = false;
        return false;
      }
      this.excel_data.total_count = this.excel_result_order_refund_list_search_count;
      /*
      this.excel_data.total_count = this.excel_result_refund_list_total_count;
      if (this.excel_data.total_count > this.excel_data.total_limit) {
        alert("엑셀 다운로드 건 수는 " + this.excel_data.total_limit.toLocaleString("ko-KR") + "건</br>이내로 검색하여 다운로드 해주세요.");
        this.excel_loading = false;
        return false;
      }
        */

      let excel_data_array = [];
      for (const result_refund of this.excel_result_order_refund_list) {
        //for (const result_refund of this.order_refund_list) {
        /*

        let excel_datas = {
          member_id: result_refund.member_id != null ? result_refund.member_id : "-",
        };
        */
        let excel_datas = this.makeExcelData(result_refund);
        excel_data_array.push(excel_datas);
      }
      this.excel_data.sheetData = excel_data_array;
      this.initExcelDownload();
      this.excel_loading = false;
    },
    makeExcelData: function (data) {
      const text_order_status = data.order_status.startsWith("N")
        ? Filters.reservationStatusType(data.order_status) + "(" + Filters.orderStatusType(data.order_status) + ")  "
        : data.order_status_text;
      return {
        order_date: data.order_date,
        payment_date: data.payment_date,
        cancel_request_date: data.cancel_request_date,
        cancel_accept_date: data.cancel_accept_date,
        order_id: data.order_id,
        order_item_code: data.order_item_code,
        order_status: text_order_status,
        product_name: data.product_name,
        order_option:
          data.product_option.length > 0
            ? data.product_option.map((option) => option.option_name + " : " + option.option_value).join(", ")
            : "",
        order_user_name: data.order_user_name,
        order_user_id: data.order_user_id ? data.order_user_id : "(비회원)",
        order_user_phone: data.order_user_phone,
        used_date: data.used_date,
        product_price: data.product_price,
        product_quantity: data.product_quantity,
        product_total_price: data.product_total_price,
        penalty_price: data.penalty_price,
        expected_refund_amount: data.expected_refund_amount,
        actual_refund_amount: data.actual_refund_amount,
        remain_amount: data.remain_amount,
        refund_memo: data.refund_memo,
        payment_method: data.payment_info.payment_method,
        payment_amount: data.payment_info.payment_amount,
        use_point: data.payment_info.use_point,
        payment_company: data.payment_info.payment_company,
      };
    },
    /**
     * 엑셀 시작
     */
    initExcelDownload: function () {
      this.excel_data.Excel = new Excel(null, this.excel_data.sheetHeader);
      this.addExcel();
    },
    /**
     * 엑셀 추가 데이터
     */
    getExcelDataAdd: async function () {
      this.excel_data.offset = this.excel_data.offset + this.excel_data.limit;
      /*
      if (this.excel_data.offset > this.excel_data.total_count) {
        await this.endExcel();
        return false;
      }
        */
      let excel_payload = JSON.parse(JSON.stringify(this.search_param));
      excel_payload["limit"] = this.excel_data.limit;
      excel_payload["offset"] = this.excel_data.offset;
      if (!this.search_param.cs_status) {
        excel_payload["cs_type"] = null;
      } else {
        if (this.search_param.cs_status.length === 4) {
          excel_payload["cs_type"] = "all";
        } else {
          excel_payload["cs_type"] = this.search_param.cs_status.join(",");
        }
      }
      if (!this.search_param.order_status) {
        excel_payload["order_type"] = null;
      } else {
        if (this.search_param.order_status.length === 6) {
          excel_payload["order_type"] = "all";
        } else {
          excel_payload["order_type"] = this.search_param.order_status.join(",");
        }
      }
      delete excel_payload["order_status"];
      delete excel_payload["cs_status"];

      await this.$store.dispatch("order/getExcelResultRefund", excel_payload);
      let data = this.excel_result_order_refund_list;
      if (data.length === 0) {
        await this.endExcel();
        return false;
      }

      let excel_data_array = [];
      for (const result_refund of this.excel_result_order_refund_list) {
        /*
        let excel_datas = {
          member_id: result_refund.member_id != null ? result_refund.member_id : "-",
        };
        */
        let excel_datas = this.makeExcelData(result_refund);
        excel_data_array.push(excel_datas);
      }
      this.excel_data.sheetData = excel_data_array;

      this.excel_data.Excel.addSheet(this.excel_data.sheetData);
      this.addExcel();
    },
    /**
     * 시트에 엑셀 추가
     */
    addExcel: async function () {
      if (this.excel_data.offset === 0) {
        await this.excel_data.Excel.initSheet();
        await this.excel_data.Excel.addSheet(this.excel_data.sheetData);
        await this.getExcelDataAdd();
      } else {
        await this.getExcelDataAdd();
      }
    },
    /**
     * 파일 다운
     */
    endExcel: async function () {
      await this.excel_data.Excel.endSheet(`예약 주문 위약금 관리_${dayjs().format("YYYYMMDD")}`);
      this.excel_loading = false;
    },
    showModal: async function (order_item_code) {
      window.scrollTo(0, 0);
      this.is_modal_show = true;
      console.log("show order_id", order_item_code);

      let set_param = {};
      const set_order = this.order_refund_list.filter((order) => order.order_item_code == order_item_code)[0];
      set_param = {
        order_id: set_order.order_item_code,
        order_status: set_order.order_status,
        order_user_id: set_order.order_user_id,
        order_user_name: set_order.order_user_name,
        order_user_phone: set_order.order_user_phone,
        payment_method: set_order.payment_info.payment_method,
        product_total_price: set_order.product_total_price,
        actual_refund_amount: set_order.actual_refund_amount,
        expected_refund_amount: set_order.expected_refund_amount,
        penalty_price: set_order.penalty_price,
        memo: set_order.refund_memo,
      };

      await this.$refs.RefundInputModal.showModal(set_param);
    },
    closeModal: function () {
      this.is_modal_show = false;
      this.$refs.RefundInputModal.closeModal();
    },
    searchDateChange: function (index, number, type) {
      for (let search_date_btn of this.search_date_btn_class) {
        search_date_btn.selected = false;
      }
      this.search_date_btn_class[index].selected = true;

      let from = dayjs().subtract(number, type).format("YYYY-MM-DD");
      if (type == "month" && number == 3) {
        from = dayjs(from).add(1, "day").format("YYYY-MM-DD");
      }
      const to = dayjs().format("YYYY-MM-DD");

      this.search_param.from = from;
      this.search_param.to = to;
    },
    movePaymentCompany: function (pay_info) {
      //window.open(`https://${this.refund_mall_id}.cafe24.com/admin/php/shop1/c/member_admin_d_f.php?user_id=` + member_id, "_blank");
      const check_method = pay_info.payment_method;
      if (check_method.includes("무통장")) {
        window.open("https://bizbank.shinhan.com/main.html");
        //} else if (["신용카드", "가상계좌", "계좌이체"].includes(check_method)) {
      } else if (check_method.includes("신용카드") || check_method.includes("가상계좌") || check_method.includes("계좌이체")) {
        window.open("https://npg.nicepay.co.kr/logIn.do");
      } else if (check_method.includes("카카오") || check_method.includes("카카오페이")) {
        window.open("https://pg.kakao.com/");
      } else if (check_method.includes("모빌리언스") || check_method.includes("휴대폰")) {
        window.open("https://cp.mcash.co.kr/mcht/login.jsp");
      } else if (check_method.includes("네이버페이")) {
        window.open("https://admin.pay.naver.com/");
      }
    },
    displayRefundAccount: function (refund) {
      let account_no = "";
      let bank_name = "";
      let account_owner = "";
      if (refund.refund_bank_code) {
        account_no = refund.refund_bank_account_no;
        account_owner = refund.refund_bank_account_owner;
        bank_name = bank_code_list.find((item) => item.code === refund.refund_bank_code).value;
      } else if (refund.payment_info.extra_info[0].value) {
        account_no = refund.payment_info.extra_info[0].value;
        bank_name = refund.payment_info.extra_info[1].value;
        account_owner = refund.payment_info.extra_info[2].value;
      }
      if (bank_name) {
        return `환불 계좌 : ${account_no} ${bank_name}<br />
        예금주 : ${account_owner}`;
      }
    },
    displayPaymentCompanyInfo: function (pay_method) {
      if (pay_method.includes("무통장")) {
        return "신한은행 / 140-014-898787 / (주)플랫폼구사삼";
      } else if (pay_method.includes("신용카드") || pay_method.includes("가상계좌") || pay_method.includes("계좌이체")) {
        return "나이스 올더게이트";
      } else if (pay_method.includes("카카오") || pay_method.includes("카카오페이")) {
        return "카카오";
      } else if (pay_method.includes("휴대폰")) {
        return "모빌리언스";
      } else if (pay_method.includes("네이버페이")) {
        return "네이버페이";
      }
    },
    moveMemberAdminPage: function (member_id) {
      window.open(`https://${this.refund_mall_id}.cafe24.com/admin/php/shop1/c/member_admin_d_f.php?user_id=` + member_id, "_blank");
    },
    checkRemainDate: function (date) {
      const today = dayjs().startOf("day");
      const givenDate = dayjs(date).startOf("day");
      const remainingDays = givenDate.diff(today, "day");
      return "체크인 " + remainingDays + "일 전";
    },
    isFutureDate: function (date) {
      if (!date) {
        return false;
      }
      const today = dayjs().startOf("day");
      const givenDate = dayjs(date).startOf("day");
      return !givenDate.isBefore(today);
    },
    searchTid: async function (order_id) {
      const get_tid = await this.$store.dispatch("order/getOrderTid", order_id);
      if (get_tid.TID) {
        const orderIndex = this.order_refund_list.findIndex((order) => order.order_id === order_id);
        if (orderIndex !== -1) {
          this.$set(this.order_refund_list[orderIndex].payment_info, "tid", get_tid.TID);
        }
      }
    },
    getProductOptionsHtml: function (productOptions) {
      return productOptions.map((item) => `<li> - ${item.option_name.replace("timestamp", "날짜")} : ${item.option_value} </li>`).join("");
    },
    updateRefundInfo: function (info) {
      const set_order = this.order_refund_list.find((order) => order.order_item_code === info.order_item_code);

      if (set_order) {
        Object.assign(set_order, info);
      }
    },
  },
  components: {
    RefundInputModal,
    Paginate,
    DatePicker,
    HelpMemo,
  },
  filters: Filters,
};
