export const select_day_type = [
  { text: "주문일", value: "order_date" },
  {
    text: "취소신청일",
    value: "cancel_request_date",
  },
  {
    text: "취소접수일",
    value: "cancel_accept_date",
  },
  {
    text: "취소완료일",
    value: "cancel_complete_date",
  },
];

export const bank_code_list = [
  { code: "bank_02", value: "산업은행" },
  { code: "bank_03", value: "기업은행" },
  { code: "bank_04", value: "국민은행" },
  { code: "bank_05", value: "하나(외환)은행" },
  { code: "bank_07", value: "수협중앙회" },
  { code: "bank_11", value: "농협중앙회" },
  { code: "bank_12", value: "농협개인" },
  { code: "bank_13", value: "농협" },
  { code: "bank_20", value: "우리은행" },
  { code: "bank_209", value: "유안타증권" },
  { code: "bank_21", value: "조흥은행" },
  { code: "bank_218", value: "KB증권" },
  { code: "bank_23", value: "SC제일은행" },
  { code: "bank_230", value: "미래에셋증권" },
  { code: "bank_238", value: "대우증권" },
  { code: "bank_240", value: "삼성증권" },
  { code: "bank_243", value: "한국투자증권" },
  { code: "bank_247", value: "우리투자증권" },
  { code: "bank_26", value: "신한은행" },
  { code: "bank_261", value: "교보증권" },
  { code: "bank_262", value: "하이투자증권" },
  { code: "bank_263", value: "현대차증권" },
  { code: "bank_266", value: "SK증권" },
  { code: "bank_267", value: "대신증권" },
  { code: "bank_269", value: "한화증권" },
  { code: "bank_27", value: "한미은행" },
  { code: "bank_270", value: "하나대투증권" },
  { code: "bank_278", value: "신한금융투자" },
  { code: "bank_279", value: "동부증권" },
  { code: "bank_280", value: "유진투자증권" },
  { code: "bank_287", value: "메리츠증권" },
  { code: "bank_288", value: "카카오페이증권" },
  { code: "bank_289", value: "NH투자증권" },
  { code: "bank_291", value: "신영증권" },
  { code: "bank_292", value: "케이뱅크" },
  { code: "bank_293", value: "카카오뱅크" },
  { code: "bank_294", value: "LIG증권" },
  { code: "bank_295", value: "OK저축은행" },
  { code: "bank_296", value: "토스뱅크" },
  { code: "bank_297", value: "토스증권" },
  { code: "bank_31", value: "iM뱅크" },
  { code: "bank_32", value: "부산은행" },
  { code: "bank_34", value: "광주은행" },
  { code: "bank_35", value: "제주은행" },
  { code: "bank_37", value: "전북은행" },
  { code: "bank_39", value: "경남은행" },
  { code: "bank_52", value: "모건스탠리은행" },
  { code: "bank_53", value: "씨티은행" },
  { code: "bank_57", value: "유에프제이은행" },
  { code: "bank_58", value: "미즈호코퍼레이트은행" },
  { code: "bank_59", value: "미쓰비시도쿄은행" },
  { code: "bank_60", value: "뱅크오브아메리카" },
  { code: "bank_71", value: "우체국" },
  { code: "bank_76", value: "신용보증기금" },
  { code: "bank_77", value: "기술신용보증기금" },
  { code: "bank_81", value: "하나은행" },
  { code: "bank_82", value: "농협회원조합" },
  { code: "bank_83", value: "도이치은행" },
  { code: "bank_84", value: "상호저축은행" },
  { code: "bank_85", value: "새마을금고" },
  { code: "bank_86", value: "수출입은행" },
  { code: "bank_87", value: "신용협동조합" },
  { code: "bank_89", value: "홍콩상하이은행(HSBC)" },
  { code: "bank_90", value: "에이비엔암로은행" },
  { code: "bank_91", value: "산림조합" },
  { code: "bank_93", value: "한국주택금융공사" },
  { code: "bank_94", value: "서울보증보험" },
  { code: "bank_95", value: "경찰청" },
  { code: "bank_99", value: "금융결제원" },
  { code: "bank_999", value: "기타" },
];
