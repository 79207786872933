import { mapState } from "vuex";
import Paginate from "@/components/common/Paginate.vue";
import dayjs from "dayjs";
import Filters from "@/libs/filters.js";
import HelpMemo from "@/components/common/HelpMemo.vue";

export default {
  name: "refund_product_list",
  data: () => ({
    refund_product_list: [],
    refund_product_list_count: 0,
    refund_product_search_count: 0,
    all_check: false,
    page_no: 1,
    check_list: [],
    search_param: {
      search_type: "product_code" || "product_name",
      search_value: null,
      offset: 0,
      limit: 100,
    },
    search_product_type: "product_name",
    search_product_text: null,
  }),
  computed: {
    ...mapState("refund", ["product_list_total_count", "product_search_count", "product_list"]),
  },
  created: async function () {
    if (this.$route.query.access_token) {
      sessionStorage.setItem("refund_access_token", this.$route.query.access_token);
      sessionStorage.setItem("refund_mall_id", this.$route.query.mall_id);
      sessionStorage.setItem("refund_shop_no", 1);
      localStorage.setItem("refund_access_token", this.$route.query.access_token);
      localStorage.setItem("refund_mall_id", this.$route.query.mall_id);
      localStorage.setItem("refund_shop_no", 1);
    } else {
      // 세션 유효한지 체크? 필요?
      // 세션 스토리지에 값이 없는 경우 localStorage 체크
      if (!sessionStorage.refund_access_token) {
        if (localStorage.refund_access_token) {
          sessionStorage.setItem("refund_access_token", localStorage.refund_access_token);
          sessionStorage.setItem("refund_mall_id", localStorage.refund_mall_id);
          sessionStorage.setItem("refund_shop_no", localStorage.refund_shop_no);
        }
      }
    }
    if (this.$route.query) {
      if (this.$route.query.page_no) {
        this.page_no = Number(this.$route.query.page_no);
      }
      /*
      if (this.$route.query.search_value) {
        if (this.$route.query.search_type) {
          this.search_product_type = this.$route.query.search_type;
        } else {
          this.search_product_type = "product_code";
        }
        this.search_product_text = this.$route.query.search_value;
      }
        */
      if (this.$route.query.product_name) {
        this.search_product_type = "product_name";
        this.search_product_text = this.$route.query.product_name;
      } else if (this.$route.query.product_code) {
        this.search_product_type = "product_code";
        this.search_product_text = this.$route.query.product_code;
      }
    }
    // 리스트 조회
    await this.getRefundProductList();
    // 리스트 노출
    await this.setRefundProductList();
    // 페이징 노출
    this.setPaging();
  },
  methods: {
    saveRefundProduct: function (product_code) {
      this.$router.push({
        name: "refund_setting",
        query: { product_code },
      });
    },

    searchRefundProduct: async function () {
      let query = {};
      if (this.search_product_text.length > 0) {
        if (this.search_product_type == "product_code") {
          if (this.search_product_text.length != 8) {
            return alert("8자리의 상품코드를 입력해주세요.");
          } else {
            query = Object.assign({}, query, { product_code: this.search_product_text });
          }
        } else if (this.search_product_type == "product_name") {
          if (this.search_product_text.length < 3) {
            alert("3글자 이상 입력해주세요.");
            return false;
          } else {
            query = Object.assign({}, query, { product_name: this.search_product_text });
          }
        }
      }
      this.$router.push({
        name: "refund_product_list",
        query,
      });
    },
    displayConfig(info, day_type = "peak") {
      if (info && info.used) {
        if (info.config) {
          if (day_type === "bought") {
            return info.config.map((c) => `구매 후 ${c.day}일 이후 - 위약금 ${c.value}${c.unit === "percent" ? "%" : "원"}`).join("<br>");
          } else {
            return info.config
              .map((c) => `${c.day === "0" ? "당일" : `체크인 ${c.day}일 전 부터`} - ${c.value}${c.unit === "percent" ? "%" : "원"}`)
              .join("<br>");
          }
        } else {
          return "설정값 없음";
        }
      } else {
        return "사용안함";
      }
    },
    displayImage(info) {
      if (info && info.used && info.image_url) {
        return `<a class="underline hover hover-color-blue" target="_blank" href="${info.image_url}">등록함</a>`;
      } else {
        return "-";
      }
    },
    // 리스트 조회
    getRefundProductList: async function () {
      this.search_param.offset = (this.page_no - 1) * this.search_param.limit;

      // offset 수정
      // this.$set(this.search_param, "offset", (this.page_no - 1) * this.search_param.limit);

      // 성분 검색할 때
      let search_param = this.search_param;
      if (this.search_product_type && this.search_product_text) {
        this.$set(search_param, "search_type", this.search_product_type);
        this.$set(search_param, "search_value", this.search_product_text);
      } else {
        this.$delete(search_param, "search_type");
        this.$delete(search_param, "search_value");
      }
      await this.$store.dispatch("refund/getRefundProductList", search_param);
      await this.setRefundProductList();
      this.setPaging();
    },
    setRefundProductList: async function () {
      this.refund_product_list = [];
      for (const product of this.product_list) {
        this.$set(product, "checked", false);
        this.refund_product_list.push(product);
      }
      this.refund_product_list_count = Number(this.product_list_total_count);
      this.refund_product_search_count = Number(this.product_search_count);
      if (!this.search_param.search_value) {
        this.refund_product_search_count = this.refund_product_list_count;
      }
    },

    // 체크 박스 전체 선택
    allCheck: function (checked) {
      this.check_list = [];
      if (checked) {
        for (let product of this.refund_product_list) {
          this.check_list.push(product.refund_code);
        }
      }
    },
    isAllCheck: function () {
      this.all_check = this.refund_product_list.length === this.check_list.length;
    },
    // 제품 삭제
    deleteProduct: async function () {
      if (this.check_list.length === 0) {
        alert("선택된 설정 상품이 없습니다.");
        return;
      }

      if (confirm("선택하신 설정 상품을 삭제하시겠습니까?")) {
        let deletRefundCodelist = this.check_list; //[];
        /*
        for (const product of this.check_list) {
          deletRefundCodelist.push(product);
        }
          */
        await this.$store.dispatch("refund/deleteRefundProduct", deletRefundCodelist);
        alert("삭제 하였습니다.");
        this.$router.push({
          name: "refund_product_list",
          query: { time: dayjs().format("Hms") },
        });
      }
    },
    setPaging: function () {
      this.$refs.Paginate.setPaging(
        this.refund_product_list_total_count,
        this.search_param.limit,
        this.refund_product_list.length,
        this.refund_product_search_count,
      );
    },
  },
  components: {
    Paginate,
    HelpMemo,
  },
  filters: Filters,
};
