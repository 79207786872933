import M from "@/libs/mapping.js";

const actions = {
  async getRefundProductList({ commit }, params) {
    try {
      const result = await M.api(M.entry("/get/refund/product", sessionStorage.getItem("refund_shop_no")), params);
      commit("setRefundProduct", result);
    } catch (e) {
      // 실패 시 처리 할 내용
      console.log(e);
    }
  },
  async getRefundProductListSimple({ commit }, params) {
    try {
      const result = await M.api(M.entry("/get/refund/product/registed", sessionStorage.getItem("refund_shop_no")), params);
      commit("setRefundProductSimple", result);
    } catch (e) {
      console.log(e);
    }
  },
  // 설정 상품 삭제
  async deleteRefundProduct(commit, params) {
    for (const product_code of params) {
      await M.api(M.entry("/delete/refund/product", sessionStorage.getItem("refund_shop_no"), product_code));
    }
  },
  // 환불 설정 상품 수정
  async putRefundProduct(commit, params) {
    const refund_code = params.refund_code;
    await M.api(M.entry("/put/refund/product", sessionStorage.getItem("refund_shop_no"), refund_code), params);
  },
  // 환불 설정 상품 등록
  async postRefundProduct(commit, params) {
    await M.api(M.entry("/post/refund/product", sessionStorage.getItem("refund_shop_no")), params);
  },
  // 환불 설정 상품 상세정보 조회
  async getRefundProductDetail({ commit }, params) {
    const product_code = params.product_code;
    try {
      const result = await M.api(M.entry("/get/refund/product/detail", sessionStorage.getItem("refund_shop_no"), product_code));
      commit("setRefundProductDetail", result);
    } catch (e) {
      // 실패 시 처리 할 내용
      console.log(e);
    }
  },
  async getProductList({ commit }, params) {
    try {
      const result = await M.api(M.entry("/get/cafe24/product", sessionStorage.getItem("refund_shop_no")), params);
      commit("setCafe24ProductList", result);
      //commit("setProductList", result);
    } catch (e) {
      console.log(e);
    }
  },
  async getRefundProductListReturn(commit, params) {
    try {
      return await M.api(M.entry("/get/refund/product/registed", sessionStorage.getItem("refund_shop_no")), params);
    } catch (e) {
      console.log(e);
    }
  },
};

export default actions;
