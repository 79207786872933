import M from "@/libs/mapping.js";

const actions = {
  // 토큰재발급
  async getRefreshToken({ commit }) {
    try {
      const result = await M.api(M.entry("/get/auth/refresh"));
      commit("setToken", result);
    } catch (e) {
      console.log(e);
      const mall_id = sessionStorage.getItem("refund_mall_id");
      alert("카페24 인증키 값이 만료되었습니다.\n앱스토어 목록에서 다시 접속해주세요.");
      location.href = `https://${mall_id}.cafe24.com/disp/admin/shop1/myapps/list`;
      await new Promise((resolve) => {
        setTimeout(resolve, 1000);
      });
    }
  },
  // 샵 리스트 조회
  async getShops({ commit }) {
    try {
      const result = await M.api(M.entry("/get/shops"));
      commit("setShops", result);
    } catch (e) {
      console.log(e);
    }
  },
  // 이미지 등록
  async postImage(commit, params) {
    if (!params.image) {
      return;
    }

    if (params.image.indexOf("http://") !== -1 || params.image.indexOf("https://") !== -1) {
      return params.image;
    }

    try {
      const result = await M.api(M.entry("/upload/image", sessionStorage.getItem("refund_shop_no")), params);
      return result.image;
    } catch (e) {
      // return "http://naver.com";
    }
  },
};

export default actions;
