export default Object.freeze({
  orderStatusType: (value) => {
    switch (value) {
      case "N10":
        return "상품준비중";
      case "N20":
        return "배송준비중";
      case "N21":
        return "배송대기";
      case "N22":
        return "배송보류";
      case "N30":
        return "배송중";
      case "N40":
        return "배송완료";
      case "N50":
        return "구매확정";
      case "C00":
        return "취소신청";
      case "C10":
      case "C11":
        return "취소접수";
      case "C34":
      case "C35":
      case "C36":
        return "취소처리중";
      case "C40":
      case "C41":
      case "C42":
      case "C43":
        return "취소완료";
      default:
        return value;
    }
  },
  reservationStatusType: (value) => {
    switch (value) {
      case "N10":
        return "예약신청";
      case "N20":
        return "예약확정";
      case "N21":
        return "예약대기";
      case "N22":
        return "예약보류";
      case "N30":
        return "예약확정";
      case "N40":
        return "이용대기";
      case "N50":
        return "이용완료";
      case "C00":
      case "C10":
      case "C11":
      case "C34":
      case "C35":
      case "C36":
      case "C40":
      case "C41":
      case "C42":
      case "C43":
        return "";
      default:
        return value;
    }
  },
  singularStatus: (value) => {
    return value === "enabled" ? "단수" : "복수";
  },
  comma: (value) => {
    return value.toLocaleString("ko-KR");
  },
  flexibleMin: (param) => {
    if (param.re_prescription == "flexible") {
      return "Flexible";
    } else {
      return param.re_contain_percent.min ? param.re_contain_percent.min : 0;
    }
  },
  flexibleMax: (param) => {
    if (param.re_prescription == "flexible") {
      return "Flexible";
    } else {
      return param.re_contain_percent.max ? param.re_contain_percent.max : 0;
    }
  },
  useStatus: (value) => {
    if (value == "enabled") {
      return "사용함";
    } else {
      return "사용안함";
    }
  },
  salesStatus: (value) => {
    if (value == "enabled") {
      return "판매중";
    } else {
      return "판매안함";
    }
  },
  subJectLineBreak: (value) => {
    return value.replace(/(\n|\r\n)/g, "<br>");
  },
  removeTag: (value) => {
    if (!value) {
      return value;
    }
    const str_value = String(value);
    return str_value.replace(/<[^>]*>?/g, "");
  },
  feedbackFormulaStatus: (value) => {
    if (value && value == "enabled") {
      return " (피드백 계산식)";
    }
    return "";
  },
  linkOrderDetail: function (order_id) {
    const refund_mall_id = sessionStorage.getItem("refund_mall_id");
    return `https://${refund_mall_id}.cafe24.com/admin/php/shop1/s_new/order_detail.php?order_id=${order_id}`;
  },
});
