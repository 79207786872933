import { mapState } from "vuex";
//import dayjs from "dayjs";
import Filters from "@/libs/filters";
import FileReader from "@tanker/file-reader";

export default {
  name: "refund_product_save",
  data: () => ({
    basic_config: {
      day: "",
      value: "",
      unit: "percent",
    },
    search_product_type: "product_name",
    search_product_text: "",
    search_product_list: [],
    setting_product_code: null,
    setting_product_info: null,
    bought_penalty_status: "N",
    bought_penalty_config: [],
    used_penalty_status: "N",
    used_penalty_config: [],
    peak_used_penalty_days: "",
    peak_used_penalty_status: "N",
    peak_used_penalty_config: [],
    display_image_status: "N",
    display_image: null,
    display_image_url: null,
    memo: null,
    is_update: false,
    is_searching: false,
    refund_code: null,
    refund_product_list: [],
    save_loading: false,
    penalty_unit_options: [
      { value: "percent", text: "%" },
      { value: "won", text: "₩" },
    ],
  }),
  computed: {
    ...mapState("refund", ["cafe24_product_list", "product_detail", "product_list"]),
  },
  created: async function () {
    window.scrollTo(0, 0);
    this.reset();
    if (this.$route.query && this.$route.query.product_code) {
      this.is_update = true;
      // 제품 정보 조회
      this.setting_product_code = this.$route.query.product_code;
      await this.getRefundProductDetail();
      // 제품 정보 노출
      await this.setRefundProductDetail();
    } else {
      const bought_config = JSON.parse(JSON.stringify(this.basic_config));
      this.bought_penalty_config.push(bought_config);
      const used_config = JSON.parse(JSON.stringify(this.basic_config));
      this.used_penalty_config.push(used_config);
      const peak_config = JSON.parse(JSON.stringify(this.basic_config));
      this.peak_used_penalty_config.push(peak_config);
    }
    await this.getRefundProductList();
  },
  methods: {
    reset: function () {
      Object.assign(this.$data, this.$options.data());
    },
    movePage: function () {
      this.$router.push({
        name: "refund_product_list",
      });
    },
    getRefundProductDetail: async function () {
      const set_param = {
        product_code: this.setting_product_code,
      };
      await this.$store.dispatch("refund/getRefundProductDetail", set_param);
    },
    // 제품 정보 노출
    setRefundProductDetail: async function () {
      this.bought_penalty_config = this.product_detail.bought_penalty.config;
      this.bought_penalty_status = this.product_detail.bought_penalty.used === true ? "Y" : "N";
      this.used_penalty_config = this.product_detail.used_penalty.config;
      this.used_penalty_status = this.product_detail.used_penalty.used === true ? "Y" : "N";
      this.peak_used_penalty_days = this.product_detail.peak_used_penalty.days;
      this.peak_used_penalty_status = this.product_detail.peak_used_penalty.used === true ? "Y" : "N";
      this.peak_used_penalty_config = this.product_detail.peak_used_penalty.config;
      this.display_image_status = this.product_detail.penalty_image.used === true ? "Y" : "N";
      this.display_image_url = this.product_detail.penalty_image.image_url;
      this.display_image = this.display_image_url;
      this.refund_code = this.product_detail.refund_code;
      this.memo = this.product_detail.memo;
      this.setting_product_code = this.product_detail.product_code;
      this.setting_product_info = {
        product_name: this.product_detail.product_name,
        list_image: this.product_detail.detail_image,
        price: this.product_detail.product_price,
      };
    },
    requireParameterCheck: async function () {
      if (!this.setting_product_code) {
        alert("위약금 설정 상품을 선택해주세요.");
        return false;
      }
      const set_param = {
        product_code: this.setting_product_code,
      };

      // 등록
      if (!this.is_update) {
        const result = await this.$store.dispatch("refund/getRefundProductListReturn", set_param);
        if (result.length > 0) {
          alert("이미 등록된 상품 코드입니다.");
          return false;
        }
      }

      return true;
    },
    searchProduct: async function () {
      if (this.is_searching) {
        alert("상품 조회 중입니다.");
        return;
      }
      if (!this.search_product_text) {
        alert("검색어를 입력해주세요.");
        return;
      }
      const set_param = {
        search_type: this.search_product_type,
        search_value: this.search_product_text,
      };
      this.search_product_list = [];
      this.is_searching = true;
      await this.$store.dispatch("refund/getProductList", set_param);
      await this.setSearchProduct();
      this.is_searching = false;
    },
    setSearchProduct: async function () {
      if (this.refund_product_list.length > 0 && this.cafe24_product_list.length > 0) {
        this.search_product_list = this.cafe24_product_list.map((product) => {
          const is_regist = !!this.refund_product_list.find((refund_product) => refund_product.product_code === product.product_code);
          return { ...product, is_regist };
        });
      } else {
        this.search_product_list = this.cafe24_product_list;
      }
    },
    selectProduct: function (select_code) {
      this.setting_product_code = select_code;
      // set value this.setting_product_info when select_code same product_code in this.search_product_list
      this.setting_product_info = this.search_product_list.find((product) => product.product_code === select_code);

      this.search_product_list = [];
      //console.log(this.setting_product_code, this.setting_product_info);
    },
    deleteSelectedProduct: function () {
      this.setting_product_code = null;
      this.setting_product_info = null;
    },
    addPenaltyConfig: function (type = "bought") {
      const config = {
        day: "",
        value: "",
        unit: "percent",
      };
      if (type === "bought") {
        this.bought_penalty_config.push(config);
      } else if (type === "used") {
        this.used_penalty_config.push(config);
      } else if (type === "peak_used") {
        this.peak_used_penalty_config.push(config);
      }
    },
    deletePenaltyConfig: function (type = "bought", index) {
      if (type === "bought") {
        this.bought_penalty_config.splice(index, 1);
      } else if (type === "used") {
        this.used_penalty_config.splice(index, 1);
      } else if (type === "peak_used") {
        this.peak_used_penalty_config.splice(index, 1);
      }
    },
    productImageSelect: function (e, type) {
      if (type == "input") {
        e.target.parentNode.lastChild.click();
      } else {
        e.target.parentNode.parentNode.lastChild.click();
      }
    },
    productImageFileChange: async function (e) {
      const max_file_size = 3 * 1024 * 1024; //3MB
      let file = e.target.files[0];
      let file_name = null;
      if (file) {
        let file_size = file.size;
        if (max_file_size < file_size) {
          e.target.value = null;
          alert("파일 용량을 확인해주세요.");
          return;
        }
        file_name = file.name;
        await this.productUploadImgfile(e.target);
      }

      this.display_image = file_name;
      if (!file_name) {
        this.display_image = null;
      }
    },
    productUploadImgfile: async function (e) {
      let file_info = e.files[0];
      if (!file_info) {
        alert("이미지를 등록해주세요.");
        return false;
      }
      const reader = new FileReader(file_info);
      const dataUrl = await reader.readAsDataURL();
      this.display_image_url = dataUrl;
    },
    productImageShow: function () {
      let newTab = window.open();
      newTab.document.title = "이미지 보기";
      newTab.document.body.innerHTML = '<div><img src="' + this.display_image_url + '" ></div>';
    },
    updateProduct: async function () {
      if (this.save_loading == true) {
        return;
      }
      const is_check = await this.requireParameterCheck();
      if (is_check === false) {
        return;
      }
      this.save_loading = true;
      await this.uploadImage();
      if (!this.display_image_url) {
        this.display_image_status = "N";
      }
      const set_param = {
        product_name: this.setting_product_info.product_name,
        product_code: this.setting_product_code,
        product_price: this.setting_product_info.price,
        bought_penalty: {
          used: this.bought_penalty_status === "Y" ? true : false,
          config: this.bought_penalty_config,
        },
        used_penalty: {
          used: this.used_penalty_status === "Y" ? true : false,
          config: this.used_penalty_config,
        },
        peak_used_penalty: {
          days: this.peak_used_penalty_days,
          used: this.peak_used_penalty_status === "Y" ? true : false,
          config: this.peak_used_penalty_config,
        },
        memo: this.memo,
        penalty_image: {
          used: this.display_image_status === "Y" ? true : false,
          image_url: this.display_image_url,
        },
        refund_code: this.refund_code,
      };
      console.log("modify Product", set_param);
      await this.$store.dispatch("refund/putRefundProduct", set_param);
      this.save_loading = false;
      alert("수정되었습니다.");
      //this.movePage();
    },
    uploadImage: async function () {
      if (this.save_loading !== true) {
        return;
      }
      if (this.display_image) {
        const image = await this.$store.dispatch("common/postImage", { image: this.display_image_url });
        this.display_image_url = image;
      }
      return this.display_image_url;
    },
    createProduct: async function () {
      if (this.save_loading == true) {
        return;
      }
      const is_check = await this.requireParameterCheck();
      if (is_check === false) {
        return;
      }
      this.save_loading = true;
      await this.uploadImage();
      if (!this.display_image_url) {
        this.display_image_status = "N";
      }
      const set_param = {
        product_name: this.setting_product_info.product_name,
        product_code: this.setting_product_code,
        product_price: this.setting_product_info.price,
        bought_penalty: {
          used: this.bought_penalty_status === "Y" ? true : false,
          config: this.bought_penalty_config,
        },
        used_penalty: {
          used: this.used_penalty_status === "Y" ? true : false,
          config: this.used_penalty_config,
        },
        peak_used_penalty: {
          days: this.peak_used_penalty_days,
          used: this.peak_used_penalty_status === "Y" ? true : false,
          config: this.peak_used_penalty_config,
        },
        memo: this.memo,
        penalty_image: {
          used: this.display_image_status === "Y" ? true : false,
          image_url: this.display_image_url,
        },
      };
      await this.$store.dispatch("refund/postRefundProduct", set_param);
      this.save_loading = false;
      alert("저장되었습니다.");
      this.movePage();
    },
    getRefundProductList: async function () {
      await this.$store.dispatch("refund/getRefundProductListSimple");
      this.setRefundProductList();
    },
    setRefundProductList: async function () {
      this.refund_product_list = this.product_list;
    },
  },
  filters: Filters,
};
