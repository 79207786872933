export default {
  name: "refund_paginate",
  data: () => ({
    page_no: 1,
    page_befor_no: 1,
    page_after_no: 2,
    page_last_no: 1,
    page_total_count: 1, // 총 page number 수
    page_limit: 5, // 노출될 page number 수
    page_numbers: [], // 노출될 page number
    list_count: 0, // 리스트 총 수
    list_limit: 20, // 노출될 개수
    search_count: 0, // 검색 수
    is_show: false,
    query: null,
  }),
  methods: {
    reset: function () {
      Object.assign(this.$data, this.$options.data());
    },
    getParams: async function () {
      //
    },
    // 페이징 설정
    setPaging: function (list_count, list_limit, real_count, search_count) {
      // 총 등록 수
      this.list_count = list_count;
      this.list_limit = list_limit;
      this.search_count = search_count;
      if (this.list_limit > search_count) {
        this.is_show = false;
        return;
      }
      // 실제 조회된 내용이 limit 보다 크면 진행
      if (real_count > 0) {
        this.setPageNo();
      } else {
        this.is_show = false;
      }
    },
    setPageNo: function () {
      // this.page_total_count = Math.ceil(this.list_count / this.list_limit);
      // this.page_last_no = Math.ceil(this.list_count / this.list_limit);

      this.page_total_count = Math.ceil(this.search_count / this.list_limit);
      this.page_last_no = Math.ceil(this.search_count / this.list_limit);

      if (this.$route.query && this.$route.query.page_no) {
        this.page_no = Number(this.$route.query.page_no);
        if (this.page_no > 1) {
          this.page_befor_no = this.page_no - 1;
          this.page_after_no = this.page_no + 1;

          if (this.page_after_no > this.page_total_count) {
            this.page_after_no = this.page_total_count;
          }
        } else {
          this.page_befor_no = 1;
          this.page_after_no = 2;
        }
      }
      const page = Math.ceil(this.page_no / this.page_limit);
      let first = (page - 1) * this.page_limit + 1;
      let last = page * this.page_limit;
      if (last > this.page_total_count) {
        last = this.page_total_count;
      }
      this.page_numbers = [];
      for (let i = first; i <= last; i++) {
        this.page_numbers.push(Number(i));
      }
      this.is_show = false;
      if (this.page_total_count > 1) {
        this.is_show = true;
      }
    },
    move: function (v) {
      if (this.$route.query) {
        this.query = this.$route.query;
      }

      Object.assign({}, this.query, { page_no: v });

      let query_text = "";
      for (const query in this.query) {
        if (query != "page_no") {
          if (this.query[query] == null || this.query[query] == "null") {
            continue;
          }
          query_text += query + "=" + this.query[query] + "&";
        }
      }
      query_text += "page_no=" + v;

      let return_path = this.$route.path + "?" + query_text;

      return return_path;
    },
  },
  created: function () {
    // this.reset();
  },
};
