<template>
  <div class="ec-base-box typeThin refund_main board_background">
    <button type="button" class="icon eTip mr-2 cursor-default">도움말</button>
    <p class="bold board_background">
      {{ helpText }}
    </p>
  </div>
</template>


<script>
export default {
  name: 'HelpMemo',
  props: {
    helpText: {
      type: String,
      required: true,
    },
  },
  methods: {
  },
};
</script>